"use client"
import React, { useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {toast } from 'sonner'
import {useForm} from 'react-hook-form';
import {hardGray, hover, softWhite, text, white} from '@/styles/globalStyleVars';
import Button from "@/components/common/ui/Button";
import {usePathname} from "next/navigation";
import ImageSocialBox from "@/components/common/ImageSocialBox";
import axios from "axios";
import TextInput from "@/components/common/ui/forms/TextInput";
import {errorToast, successToast} from "@/components/hooks/toastNotifications";


const ContactForm = ({padding, data, id, pt}) => {

    const [offset, setOffset] = useState(90);
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});
    const location = usePathname();
    const {errors, isSubmitSuccessful} = formState;
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [selectResponseData, setSelectResponseData] = useState(null);

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 0);
    }, [location.pathname]);



    const onSubmit = async (e) => {

        setIsSubmitting(true); // Show spinner
        try {
            const formData = new FormData();
            formData.append('name', e?.name);
            formData.append('email', e?.email);
            formData.append('phone', e?.phone);
            formData.append('qualification', e?.qualification);
            formData.append('english', e?.english);
            formData.append('prefered_program', e?.prefered_program);
            formData.append('target_university', e?.target_university);
            formData.append('intended_intake', e?.intended_intake);

            formData.append('form_id', 'get-in-touch');
            const response = await axios.post('https://project.bestinbd.com/projects/web/2406MEM/api/post-req-data/form-submit', formData);

            if (response?.data?.result === 'success') {
                // successToast(response?.data?.message);
                reset(); // This will reset the form fields
            } else if (response?.data?.result === 'error') {
                errorToast(response?.data?.message || 'An error occurred. Please try again.');
            }
        } catch (err) {
            errorToast(err?.message || 'Failed to submit form. Please try again later.');

        } finally {
            setIsSubmitting(false); // Hide spinner
        }
    };

    const onError = (errors) => {
        const count = Object.values(errors).length;
        if (count > 0) {
            toast.error('Please fill out the correct inputs');
        }
    };
    useEffect(() => {
        if (selectResponseData?.error) {
            errorToast(selectResponseData.error);
        }
        if (selectResponseData?.data?.result === 'success') {
            successToast(selectResponseData.data?.message);
        }
    }, [selectResponseData]);


    // const selectLocationInputRef = useRef();




    return (<StyledListWithForm pt={pt} id={`${id ? id : 'ListWithForm'} `}

                                className={`list_with_form ${padding ? padding : ''} `}>

        <Container fluid >
            <Row>

                <Col md={6} className={'pr-0'}>
                    <ImageSocialBox offset={offset} img={'/images/dynamic/common/form-modal.jpg'}/>
                </Col>
                <Col className={'form-column'} md={6}>
                    <div className="">

                    </div>
                    <div className="form_wrapper" style={{paddingRight: offset + 'px'}}>
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                            <input name={'spam_protector'} type="hidden"/>
                            <input name={'form_id'} value={'contact-form'} type="hidden"/>
                            <div className="form-group">
                                <Form.Group controlId="formBasicEmail">

                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register('name', {
                                            required: 'Please enter your full name',
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: 'Name must contain only letters',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                    {errors.name && <span className="error-message form-error">{errors.name.message}</span>}

                                </Form.Group>
                            </div>

                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register('email', {
                                                    required: {
                                                        value: true, message: 'please enter your email'
                                                    }, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Please enter a valid email address'
                                                    }
                                                })}
                                                type="email"
                                                placeholder="Email*"/>
                                            {errors.email && <span className="error-message form-error">{errors.email.message}</span>}

                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">

                                            <Form.Control
                                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register('phone', {
                                                    required: {
                                                        value: true, message: 'Please enter your phone first'
                                                    }, pattern: {
                                                        value: /^01[0-9]{9}$/,
                                                        message: 'Please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number"
                                                placeholder="Phone Number*"/>
                                            {errors.phone && <span className="error-message form-error">{errors.phone.message}</span>}

                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            <TextInput
                                name="qualification"
                                placeholder="Highest Qualification"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Highest Qualification',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'Qualification must contain only letters',
                                    },
                                }}
                                error={errors.qualification}
                            />

                            <TextInput
                                name="english"
                                placeholder="English Test Details"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter English Test Details',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'Details must contain only letters',
                                    },
                                }}
                                error={errors.english}
                            />

                            <TextInput
                                name="prefered_program"
                                placeholder="Preferred Program"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Preferred Program',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'Program must contain only letters',
                                    },
                                }}
                                error={errors.prefered_program}
                            />

                            <TextInput
                                name="target_university"
                                placeholder="Target University"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Target University',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'University must contain only letters',
                                    },
                                }}
                                error={errors.target_university}
                            />

                            <TextInput
                                name="intended_intake"
                                placeholder="Intended Intake"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter intended Intake',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'Intake must contain only letters',
                                    },
                                }}
                                error={errors.intended_intake}
                            />


                            <div className="note">
                                <p>* We will not share your details with others</p>
                            </div>
                            <div className={'form-group '}>
                                <div onClick={handleSubmit(onSubmit, onError)}>

                                    <Button height={45} borderRadius={25} width={'210px'} text={'Submit Message'}/>

                                </div>

                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
    box-sizing: border-box;
    position: relative;
    margin-top: -1px;
    padding-top: ${(props) => (props.pt ? props.pt + 'px' : '0')};

    .container-fluid {
        padding-left: 0;
    }

    .dc-btn a {
        border: none;
    }

    .single_image_image_wrp {
        padding-top: unset !important;

        @media (max-width: 992px) {
            padding-top: 108.772% !important;
        }
    }


    .social {
        a {
            &:hover {
                path {
                    fill: ${text} !important;
                }
            }
        }
    }


    .note {
        margin-bottom: 30px;

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .form_wrapper {
        padding: 70px 0 70px 70px;
        height: 100%;
        position: relative;
        // overview_bg

        .left-col {
            div {
                height: 100%;
            }
        }


        form {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .textarea {
            min-height: 120px;
        }

        .title {
            font-size: 24px;
            font-weight: 4000;
            line-height: 28px;
            color: #F9F9F9;
            margin: 0 0 40px;
        }

        .form-group {
            margin-bottom: 30px;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                display: block;
            }

            .form-control {
                box-shadow: none;
                outline: 0;
                border-radius: 0;
                background: transparent;
                height: auto;
                border-left: none;
                border-right: none;
                border-top: none;
                border-color: rgba(167, 161, 159, 0.5);
                padding: 0 0 8px;
                color: ${text};
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.16px;
                margin-bottom: 0;

                svg {
                    display: block;
                }

                &:focus {
                    border-color: ${text};

                }

                &::placeholder {
                    color: rgba(167, 161, 159, 0.5);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.16px;

                }
            }
        }


    }

    .row {
        .form-group {
            margin-bottom: 40px !important;
        }
    }

    .form-column {
        background: ${white};
    }


    .form_wrapper {
        .row {
            margin: 0;
            width: 100%;
            gap: 15px;
            justify-content: space-between;

            .col-md-6 {
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
                padding-left: 0;
                padding-right: 0;
            }
        }
    }


    //selector

    .filter__menu{
        border: 1px solid ${white} !important;
    }
    .filter__control {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top: none;
        padding-left: 0;
        background: ${white};
        cursor: pointer;

        .filter__value-container {
            padding-left: 0;
            padding-right: 0;
        }

        .filter__single-value {
            color: ${text};
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__placeholder {
            color: rgba(167, 161, 159, 0.5);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__indicator-separator {
            display: none;
        }

        .filter__indicator {
            padding-right: 0;

            svg{
                line{
                    stroke:  rgba(167, 161, 159, 0.5);
                }
            }
        }
    }

    .filter__control--is-focused {
        outline: none;
        border-bottom: 1px solid rgba(167, 161, 159, 0.5) !important;
        box-shadow: none;
    }


    @media (max-width: 992px) {
        transform: none;
        .social {
            align-items: flex-start !important;
            justify-content: flex-start !important;
        }

        .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .container-fluid {
            padding-left: 0 !important;
        }

        .single_content_wrp {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }

        .form_wrapper {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }
    }
`;

export default React.memo(ContactForm);
